import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    firstName: "",
    lastName: "",
    companyID: "",
    userId: "",
  },
  reducers: {
    clearName: state => {
      state.firstName = "";
      state.lastName = "";
    },
    setName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    setCompany: (state, action) => {
      state.companyID = action.payload;
    },
    clearCompany: state => {
      state.companyID = "";
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearUserId: state => {
      state.userId = "";
    },
  }
})

// Action creators are generated for each case reducer function
export const { clearName, setName, setCompany, clearCompany, setUserId, clearUserId} = userSlice.actions

export default userSlice.reducer