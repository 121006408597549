export class Technician {
  constructor(
    companyID,
    credentialsID,
    email,
    firstName,
    lastName,
    id
  ) {
    this.companyID = companyID;
    this.credentialsID = credentialsID;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.id = id;
  }
}
