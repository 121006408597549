/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//React
import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "page_components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "page_components/Navbars/DashboardNavbar";
import Footer from "page_components/Footer";
import ChemicalAppliedBarChart from "page_components/Charts/BarCharts/ChemicalAppliedBarChart";
import ChemicalUsageBarChart from "page_components/Charts/BarCharts/ChemicalUsageBarChart";
import ComplexStatisticsCard from "page_components/Cards/StatisticsCards/ComplexStatisticsCard";

//Redux
import { useSelector } from "react-redux";

import { format, endOfDay } from "date-fns";

//Supabase
import { supabase } from "supabaseClient";
import HorizontalBarChart from "page_components/Charts/BarCharts/HorizontalBarChart";

function Dashboard() {
  //const { sales, tasks } = reportsLineChartData;

  const [labels, setLabels] = useState(["M", "T", "W", "T", "F", "S", "S"]);
  const [dataSet, setDataset] = useState([50, 20, 10, 22, 50, 10, 40]);
  // eslint-disable-next-line no-unused-vars
  const [selectedDate, setSelectedDate] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [selectedTechID, setSelectedTechID] = useState(-1);
  const [totalSprayedToday, setTotalSprayedToday] = useState(0);
  const [totalSprayedMonthly, setTotalSprayedMonthly] = useState(0);
  const [totalSprayedByTech, setTotalSprayedByTech] = useState(0);
  const [monthlyServiceCount, setMonthlyServiceCount] = useState(0);
  const [monthlySpendAmount, setMonthlySpendAmount] = useState(0);
  const [timeSpentSprayingMonthly, setTimeSpentSprayingMonthly] = useState(0);

  const companyID = useSelector((state) => state.user.companyID);

  //Initial load
  useEffect(() => {
    getWeeklyData();
    getDailyData();
    getTechData();
    getMonthlyServiceStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyID]);

  //Selected date changes
  useEffect(() => {
    getDailyData();
    getTechData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  //Selected technician changes
  useEffect(() => {
    getTechData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTechID]);

  const getWeeklyData = async () => {
    let days = [];
    let amountsSprayed = [];
    //Get current day
    let todayAtMidnight = selectedDate;
    let tomorrowAtMidnight = selectedDate;

    todayAtMidnight.setHours(0, 0, 0, 0);
    tomorrowAtMidnight.setDate(todayAtMidnight.getDate() + 1);
    //For each of the past 7 days
    for (let i = 1; i < 8; i++) {
      let currentDay = new Date();
      currentDay.setDate(tomorrowAtMidnight.getDate() - i);
      //Get the day of the week and add it an array
      days.push(format(currentDay, "MMM do"));
      //Get the total gallons sprayed
      let yesterday = new Date();
      let tomorrow = new Date();
      tomorrow.setDate(currentDay.getDate() + 1);
      yesterday.setDate(currentDay.getDate() - 1);
      const { data, error } = await supabase
        .from("service")
        .select()
        .eq("company_id", companyID)
        .lt("start_time", tomorrow.toISOString())
        .gt("start_time", yesterday.toISOString());

      let totalGallons = 0;
      if (data) {
        data.forEach((doc) => {
          if (
            doc.time_spent_spraying > 10 &&
            doc.total_gallons_sprayed > 0.01
          ) {
            totalGallons += doc.total_gallons_sprayed;
          }
        });
      }
      amountsSprayed.push(totalGallons.toFixed(1));
    }

    setLabels(days.reverse());
    setDataset(amountsSprayed.reverse());
  };

  const getDailyData = async () => {
    //Calculate daily total
    let selectedDay = selectedDate;
    selectedDay.setHours(0, 0, 0, 0);

    //Get the total gallons sprayed
    let yesterday = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(selectedDay.getDate() + 1);
    yesterday.setDate(selectedDay.getDate() - 1);

    const { data, error } = await supabase
      .from("service")
      .select()
      .eq("company_id", companyID)
      .lt("start_time", tomorrow.toISOString())
      .gt("start_time", yesterday.toISOString());

    let totalGallons = 0;
    if (data) {
      data.forEach((doc) => {
        if (doc.time_spent_spraying > 10 && doc.total_gallons_sprayed > 0.01) {
          totalGallons += doc.data().totalGallonsSprayed;
        }
      });
    }
    setTotalSprayedToday(totalGallons.toFixed(1));
  };

  const getTechData = async () => {
    //Calculate daily total
    let selectedDay = selectedDate;
    selectedDay.setHours(0, 0, 0, 0);

    //Get the total gallons sprayed
    let yesterday = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(selectedDay.getDate() + 1);
    yesterday.setDate(selectedDay.getDate() - 1);

    const { data, error } = await supabase
      .from("service")
      .select()
      .eq("technician_id", selectedTechID)
      .lt("start_time", tomorrow.toISOString())
      .gt("start_time", yesterday.toISOString());

    let totalGallons = 0;
    if (data) {
      data.forEach((doc) => {
        if (
          doc.data().timeSpentSpraying > 10 &&
          doc.data().totalGallonsSprayed > 0.01
        ) {
          totalGallons += doc.data().totalGallonsSprayed;
        }
      });
    }
    setTotalSprayedByTech(totalGallons.toFixed(1));
  };

  const getMonthlyServiceStats = async () => {
    //Calculate daily total
    var currentDate = new Date();
    var firstDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    var lastDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    const { data, error } = await supabase
      .from("service")
      .select()
      .eq("company_id", companyID)
      .lt("start_time", endOfDay(lastDay).toISOString())
      .gt("start_time", firstDay.toISOString());

    let totalServices = 0;
    let totalVolumeSprayed = 0.0;
    let totalTimeSpentSpraying = 0;
    if (data) {
      data.forEach((doc) => {
        if (doc.time_spent_spraying > 10 && doc.total_gallons_sprayed > 0.01) {
          totalServices += 1;
          totalTimeSpentSpraying += doc.time_spent_spraying;
          totalVolumeSprayed += doc.total_gallons_sprayed;
        }
      });
    }
    setMonthlyServiceCount(totalServices.toFixed(0));
    setTotalSprayedMonthly(totalVolumeSprayed.toFixed(1));
    setTimeSpentSprayingMonthly(totalTimeSpentSpraying.toFixed(1));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4.5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} lg={8}>
            <MDBox mb={5}>
              <ChemicalAppliedBarChart
                color="dark"
                title="Chemical Applied"
                description="Gallons of chemical used per day"
                date="Just Updated"
              />
            </MDBox>
            <MDBox mb={3}>
              <ChemicalUsageBarChart
                color="primary"
                title="Chemical Inventory Tracker"
                description="Amount of chemicals used per day"
                date="Just Updated"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={5}>
              <ComplexStatisticsCard
                icon="data_usage"
                title="Services Measured (This Month)"
                count={monthlyServiceCount + " Services"}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
            {/*<MDBox mb={5}>
              <ComplexStatisticsCard
                color="success"
                icon="attach_money"
                title="Billable Usage (This Month)"
                count={"$" + monthlySpendAmount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>*/}
            <MDBox mb={5}>
              <ComplexStatisticsCard
                color="dark"
                icon="opacity"
                title="Total Volume Sprayed (This Month)"
                count={totalSprayedMonthly + " Gallons"}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
            <MDBox mb={5}>
              <ComplexStatisticsCard
                color="light"
                icon="access_time"
                title="Time Spent Spraying (This Month)"
                count={new Date(timeSpentSprayingMonthly * 1000)
                  .toISOString()
                  .substring(11, 19)}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
