/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import React from "react";

// react-router-dom components
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Alert, AlertTitle } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";
import MDInput from "theme_components/MDInput";
import MDButton from "theme_components/MDButton";

// Images
import LogoLayout from "../components/LogoLayout";
import brandDark from "assets/images/logo-main-blue.png";

//Date
import { formatISO } from "date-fns";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { setName, setCompany, clearUserId, setUserId } from "redux/userSlice";

//Supabase
import { supabase } from "supabaseClient";

function Cover() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [authLoading, setAuthLoading] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);
  const [authErrorMessage, setAuthErrorMessage] = React.useState(false);
  const userId = useSelector((state) => state.user.userId);
  const dispatchRedux = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = location.state?.path || "/dashboard";

  React.useEffect(() => {
    getAuthSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuthSession = async () => {
    const { data, error } = await supabase.auth.getSession();
    if (data && data.session !== null) {
      dispatchRedux(setUserId(data.session.user.id));
    }
    if (error) {
      console.log(error);
    }
  };

  const handleClick = async () => {
    setAuthLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    console.log(data);
    if (data && data.user !== null) {
      await setUsersInfo(data.user.id);
      dispatchRedux(setUserId(data.user.id));
    }
    if (error) {
      setAuthError(true);
      setAuthErrorMessage(error);
      console.log(error);
    }
    setAuthLoading(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const openPasswordReset = () => {
    navigate("/reset-password");
  };

  const setUsersInfo = async (uid) => {
    const { data, error } = await supabase
      .from("admin")
      .select("*")
      .eq("auth_id", uid);
    if (error) {
      console.log(error);
      await dispatchRedux(clearUserId());
    }
    if (data) {
      const admin = data[0];
      const adminName = {
        firstName: admin.first_name,
        lastName: admin.last_name,
      };
      await dispatchRedux(setName(adminName));
      await dispatchRedux(setCompany(admin.company_id));

      //Update login date
      const { error } = await supabase
        .from("admin")
        .update({ last_login_at: formatISO(new Date()) })
        .eq("id", admin.id);
    }
  };

  if (authError) {
    let errorText;

    if (authErrorMessage.includes("auth/invalid-email")) {
      errorText = "Invalid Email Address";
    } else if (authErrorMessage.includes("auth/wrong-password")) {
      errorText = "Incorrect Password";
    } else if (authErrorMessage.includes("auth/user-not-found")) {
      errorText = "User Not Found";
    } else {
      errorText = authErrorMessage;
    }

    return (
      <LogoLayout>
        <MDBox mx={4} mb={5}>
          <img
            src={brandDark}
            alt="Calisto Logo"
            display={"block"}
            height="50px"
          />
        </MDBox>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign In
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Welcome to the admin dashboard
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={handleEmailChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePasswordChange}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleClick}
                >
                  Sign In
                </MDButton>
              </MDBox>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorText}
              </Alert>
              <MDButton
                variant="text"
                color="info"
                fullWidth
                onClick={openPasswordReset}
              >
                Forgot password? Reset it here
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  }
  if (authLoading) {
    return (
      <LogoLayout>
        <MDBox mx={4} mb={5}>
          <img
            src={brandDark}
            alt="Calisto Logo"
            display={"block"}
            height="50px"
          />
        </MDBox>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign In
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Welcome to the admin dashboard
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={handleEmailChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePasswordChange}
                />
              </MDBox>
              <MDBox mt={4} mb={1} textAlign="center">
                <CircularProgress />{" "}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  }

  if (userId === "") {
    return (
      <LogoLayout>
        <MDBox mx={4} mb={5}>
          <img
            src={brandDark}
            alt="Calisto Logo"
            display={"block"}
            height="50px"
          />
        </MDBox>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign In
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Welcome to the admin dashboard
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={handleEmailChange}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePasswordChange}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleClick}
                >
                  Sign In
                </MDButton>
              </MDBox>
              <MDButton
                variant="text"
                color="info"
                fullWidth
                onClick={openPasswordReset}
              >
                Forgot password? Reset it here
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else {
    return <Navigate to={redirectPath} replace={true}></Navigate>;
  }
}

export default Cover;
