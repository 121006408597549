// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
///import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAlhQi2iKh251y0ADUvRKcNmEwcimQ_QGg",
  authDomain: "calisto-insight-development.firebaseapp.com",
  databaseURL: "https://calisto-insight-development-default-rtdb.firebaseio.com",
  projectId: "calisto-insight-development",
  storageBucket: "calisto-insight-development.appspot.com",
  messagingSenderId: "1020854536135",
  appId: "1:1020854536135:web:159829afecbfca50defbe2",
  measurementId: "G-Y6LD3KYNYM"
};

// Initialize Firebase
initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore();

export {auth, db};