/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "page_components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "page_components/Navbars/DashboardNavbar";
import Footer from "page_components/Footer";
import CompanyInfoCard from "page_components/Cards/InfoCards/CompanyInfoCard";
import SupportInfoCard from "page_components/Cards/InfoCards/SupportInfoCard";
import TabPanel from "page_components/TabPanel";

// Overview page components
import Header from "pages/profile/components/Header";


// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import BillingInfoCard from "page_components/Cards/InfoCards/BillingInfoCard";

function Profile() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2}/>
      <Header>
        <MDBox mt={2} mb={3} mx={3}>
        <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue} >
                <Tab
                  label="Company Info"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      business
                    </Icon>
                  }
                />
                 <Tab
                  label="Billing Details"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      credit_card
                    </Icon>
                  }
                />
                <Tab
                  label="Contact Support"
                  icon={
                    <Icon fontSize="small" sx={{ mt: -0.25 }}>
                      support
                    </Icon>
                  }
                />
              </Tabs>
            </AppBar>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
            <TabPanel value={tabValue} index={0}>
            <CompanyInfoCard
                title="Company Information"
                shadow={false}
              />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
            <BillingInfoCard
                title="Billing Details"
                info={{}}
                shadow={false}
              />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
            <SupportInfoCard
                title="Support Information"
                info={{
                  email: "support@getcalisto.com",
                  phone: "801-960-2967",
                }}
                shadow={false}
              />
      </TabPanel>
             
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Profile;
