/** 

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "pages/dashboard";
import SprayServices from "pages/sprayServices";
import Devices from "pages/devices";
import Technicians from "pages/technicians";
import SignIn from "pages/authentication/sign-in";
import Profile from "pages/profile";
import ResetPassword from "pages/authentication/reset-password";
import { RequireAuth } from "page_components/requireAuth";
import NewPasswordAdmin from "pages/authentication/new-password-admin";
import NewPasswordTech from "pages/authentication/new-password-tech";

// @mui icons
import Icon from "@mui/material/Icon";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    type: "collapse",
    name: "Services",
    key: "services",
    icon: <Icon fontSize="small">pest_control</Icon>,
    route: "/services",
    component: (
      <RequireAuth>
        <SprayServices />
      </RequireAuth>
    ),
  },
  {
    type: "collapse",
    name: "Technicians",
    key: "technicians",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/technicians",
    component: (
      <RequireAuth>
        <Technicians />
      </RequireAuth>
    ),
  },
  {
    type: "collapse",
    name: "Devices",
    key: "devices",
    icon: <Icon fontSize="small">devices</Icon>,
    route: "/devices",
    component: (
      <RequireAuth>
        <Devices />
      </RequireAuth>
    ),
  },
  {
    key: "sign-in",
    route: "/login",
    component: <SignIn />,
  },
  {
    key: "profile",
    route: "/profile",
    component: (
      <RequireAuth>
        <Profile />
      </RequireAuth>
    ),
  },
  {
    key: "reset-password",
    route: "/reset-password",
    component: <ResetPassword />,
  },
  {
    key: "new-password-admin",
    route: "/new-password-admin",
    component: <NewPasswordAdmin />,
  },
  {
    key: "new-password-tech",
    route: "/new-password-tech",
    component: <NewPasswordTech />,
  },
];

export default routes;
