/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState } from "react";

// @mui material components
import { Card, Alert, AlertTitle, CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";
import MDInput from "theme_components/MDInput";
import MDButton from "theme_components/MDButton";

// Authentication layout components
import LogoLayout from "pages/authentication/components/LogoLayout";

// Firebase
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { auth } from "firebase_settings";

// Navigation
import { useNavigate } from "react-router-dom";

//Supabase
import { supabase } from "supabaseClient";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [showReturnButton, setShowReturnButton] = useState(false);
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);
  const [errorThrown, setErrorThrown] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const sendPasswordReset = async () => {
    setErrorThrown(false);
    setLoading(true);
    const { data, error } = await supabase.auth.resetPasswordForEmail(
      email,
      { redirectTo: process.env.REACT_APP_NEW_PASSWORD_LINK }
    );
    setLoading(false);
    if (error) {
      setErrorThrown(true);
      setErrorText(error.message);
    } else {
      setErrorThrown(false);
      setShowReturnButton(true);
    }
  };

  const navigateToLoginScreen = () => {
    navigate("/login");
  };

  if (showReturnButton === true) {
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" mx={2}>
              We will send you an email with password reset information
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDTypography
              display="inline-block"
              variant="button"
              color="dark"
              mx={1}
            >
              A password reset email is on its way! Use the button below go back
              to the login screen.
            </MDTypography>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={navigateToLoginScreen}
              >
                Return to login screen
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else {
    if (error === true || errorThrown === true) {
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" mx={2}>
              We will send you an email with password reset information
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={handleEmailChange}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={sendPasswordReset}
                >
                  Send reset email
                </MDButton>
              </MDBox>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
              </Alert>
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorText}
              </Alert>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>;
    }
    if (sending === true || loading === true) {
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" mx={2}>
              We will send you an email with password reset information
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1} textAlign="center">
                <CircularProgress />{" "}
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>;
    }
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" mx={2}>
              We will send you an email with password reset information
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  onChange={handleEmailChange}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={sendPasswordReset}
                >
                  Send reset email
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  }
}

export default ResetPassword;
