/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";

// Material Dashboard 2 React example components
import PageLayout from "page_components/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "pages/authentication/components/Footer";

function LogoLayout({ children }) {
  return (
    <PageLayout>
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
          direction="column"
          display="flex"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      <Footer dark />
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
LogoLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LogoLayout;
