/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress, Alert, AlertTitle } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

//Redux
import { useSelector } from "react-redux";

//React
import { useEffect, useState } from "react";

//Supabase
import { supabase } from "supabaseClient";

function CompanyInfoCard({ title, shadow }) {
  const companyID = useSelector((state) => state.user.companyID);
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    //Get docs
    loadCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCompanyData = async () => {
    setLoading(true);

    const { data, error } = await supabase
      .from("company")
      .select()
      .eq("id", companyID);

    setError(error);
    setCompany(data[0]);
    setLoading(false);
  };

  if (company) {
    return (
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Company Name"}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{company.name}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Phone"}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{company.phone_number}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Email"}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{company.contact_email}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {"Address"}: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {company.address_line_1}
              {company.address_line_2 ? " " + company.addressLine2 : ""},{" "}
              {company.city} {company.state}, {company.zipCode}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        </MDBox>
      </Card>
    );
  }

  if (loading) {
    return (
      <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <CircularProgress />{" "}
        </MDBox>
      </Card>
    );
  }

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDTypography>There was an error loading company data</MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
CompanyInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
CompanyInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default CompanyInfoCard;
