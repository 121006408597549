// React
import { useState } from "react";

// @mui material components
import { Card, Alert, AlertTitle, CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";
import MDInput from "theme_components/MDInput";
import MDButton from "theme_components/MDButton";

// Authentication layout components
import LogoLayout from "pages/authentication/components/LogoLayout";

// Navigation
import { useNavigate } from "react-router-dom";
import { supabase } from "supabaseClient";

function NewPasswordTech() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showReturnButton, setShowReturnButton] = useState(false);
  const navigate = useNavigate();

  const updatePassword = async () => {
    //Check that password 1 and 2 are the same
    setError(false);
    setLoading(true);
    if (password1 === password2) {
      const { data, error } = await supabase.auth.updateUser({
        password: password1,
      });

      setLoading(false);
      if (error) {
        setError(true);
        setErrorText(error.message);
      } else {
        setError(false);
        setShowReturnButton(true);
      }
    } else {
      setLoading(false);
      setError(true);
      setErrorText("Passwords do not match");
    }
  };

  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
  };

  const navigateToLoginScreen = () => {
    navigate("/login");
  };

  if (showReturnButton === true) {
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Create New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDTypography
              display="inline-block"
              variant="button"
              color="dark"
              mx={1}
            >
              Password is reset! Try logging into the app with your new
              password.
            </MDTypography>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  } else {
    if (error === true) {
      return (
        <LogoLayout>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="white"
                mx={10}
              >
                Create New Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="New Password"
                    variant="standard"
                    fullWidth
                    onChange={handlePassword1Change}
                  />
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="Confirm New Password"
                    variant="standard"
                    fullWidth
                    onChange={handlePassword2Change}
                  />
                </MDBox>
                <MDBox mt={6} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={updatePassword}
                  >
                    Submit Password
                  </MDButton>
                </MDBox>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorText}
                </Alert>
              </MDBox>
            </MDBox>
          </Card>
        </LogoLayout>
      );
    }
    if (loading) {
      return (
        <LogoLayout>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="white"
                mx={10}
              >
                Create New Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="New Password"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={4}>
                  <MDInput
                    type="text"
                    label="Confirm New Password"
                    variant="standard"
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={4} mb={1} textAlign="center">
                  <CircularProgress />{" "}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </LogoLayout>
      );
    }
    return (
      <LogoLayout>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="white"
              mx={10}
            >
              Create New Password
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="New Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePassword1Change}
                />
              </MDBox>
              <MDBox mb={4}>
                <MDInput
                  type="text"
                  label="Confirm New Password"
                  variant="standard"
                  fullWidth
                  onChange={handlePassword2Change}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={updatePassword}
                >
                  Submit Password
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </LogoLayout>
    );
  }
}

export default NewPasswordTech;
