/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "page_components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "page_components/Navbars/DashboardNavbar";
import Footer from "page_components/Footer";

//Redux
import { useSelector } from "react-redux";

//Supabase
import { supabase } from "supabaseClient";

function Technicians() {
  const companyID = useSelector((state) => state.user.companyID);
  const [technicianList, setTechnicianList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const mapPageToNextCursor = useRef({});
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [queryOptions, setQueryOptions] = useState({});
  const page_size = 10;

  const fetchRows = async (newPage) => {
    setLoading(true);
    const { from, to } = getPagination(newPage, page_size);

    const { data, count, error } = await supabase
      .from("technician")
      .select("*", {
        count: "exact",
      })
      .eq("company_id", companyID)
      .order("first_name", { ascending: false })
      .range(from, to);

    setTechnicianList(data);
    setRowCount(count);
    setLoading(false);
  };

  const getPagination = (page, size) => {
    const limit = size ? +size : 3;
    const from = page ? page * limit : 0;
    const to = page ? from + size - 1 : size - 1;

    return { from, to };
  };

  React.useEffect(() => {
    //Get docs
    fetchRows(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [technicianList]);

  const handleSortModelChange = React.useCallback((sortModel) => {
    // Here you save the data you need from the sort model
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);

  const handlePageChange = (newPage) => {
    if (loading === false) {
      setPage(newPage);
      fetchRows(newPage);
    }
  };

  const renderRows = () => {
    if (rows.length > 0) {
      setRows([]);
    }
    var items = [];
    for (let index = 0; index < technicianList.length; index++) {
      const doc = technicianList[index];
      const rowNum = rowIndex + index;
      items.push({
        id: rowNum,
        firstName: doc.first_name,
        lastName: doc.last_name,
        email: doc.email,
        city: doc.city,
        state: doc.state,
      });
    }
    setRows(Array.from(items));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 0, sortable: false },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      sortable: false,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      sortable: false,
    },
  ];

  if (technicianList.length === 0 || rows.length === 0) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <CircularProgress />{" "}
        </Grid>
      </Grid>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Technicians
                </MDTypography>
              </MDBox>
              <Box sx={{ height: 500, width: "100%", padding: 2 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={page_size}
                  rowsPerPageOptions={[page_size]}
                  rowCount={rowCount}
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  page={page}
                  loading={loading}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  //checkboxSelection
                  disableSelectionOnClick
                  keepNonExistentRowsSelected
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  getRowHeight={({ id, densityFactor }) => {
                    return 50;
                  }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Technicians;
