/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Dashboard 2 React components
import MDBox from "theme_components/MDBox";
import MDTypography from "theme_components/MDTypography";
import Button from "@mui/material/Button";


function BillingInfoCard({ title, info, shadow }) {
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <CardContent>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={2}
          px={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
          <MDBox pl={1}></MDBox>
          <MDTypography
            variant="caption"
            fontWeight="medium"
            textTransform="capitalize"
          >
            (A new tab will open)
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pl={2}>
          <Button
            variant="contained"
            onClick={() =>
              window.open(
                "https://billing.stripe.com/p/login/4gwaFV40m193cx2dQQ",
                "_blank"
              )
            }
          >
            <MDTypography variant="button" fontWeight="medium" color="white">
              View Billing Details
            </MDTypography>
          </Button>
        </MDBox>
      </CardContent>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
BillingInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
BillingInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
};

export default BillingInfoCard;
